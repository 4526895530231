/* eslint-disable import/no-cycle  */
import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
  FormGroup,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { filterOptions } from '../../pages/index';

const PREFIX = 'RadioButtons';

const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  radioButton: `${PREFIX}-radioButton`,
  radio: `${PREFIX}-radio`,
};

const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },
  [`& .${classes.radioButton}`]: {
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  [`& .${classes.radio}`]: {
    width: '30px',
    height: '45x',
  },
}));

export default function RadioButtons(props) {
  const { id } = props;
  const contextCondition = useContext(filterOptions);
  const { data } = contextCondition;
  const filter = data;
  const { setData } = contextCondition;
  const setFilter = setData;
  const { t } = useTranslation();
  const [filterSearch, setFilterSearch] = useState('all');
  const phone = useMediaQuery('(min-width:600px)');
  const handleFilterSearchChange = (event) => {
    setFilterSearch(() => event.target.value);
    setFilter({ ...filter, radioValue: event.target.value });
  };
  useEffect(() => {}, [filterSearch]);
  return (
    <Root className={classes.root}>
      <Box display="flex" width="100%" justifyContent="flex-start">
        <FormControl component="fieldset">
          <RadioGroup
            id={id || 2}
            row
            aria-label={`RadioButtons${id}`}
            name={id || '2'}
            value={filter.radioValue}
            onChange={(event) => {
              handleFilterSearchChange(event);
            }}
          >
            <Box fontWeight="fontWeightBold">
              {phone && (<p>
                {t('Η αναζήτηση επιχειρήσεων να εφαρμοστεί σε')}
                {': '}
              </p>)}
            </Box>
            <FormGroup row="true" sx={{ marginLeft: 1 }}>
              <FormControlLabel
                value="all"
                control={<Radio className={!phone ? classes.radio : ''} />}
                label={!phone ? (<Typography fontSize="14px">
                  {t('Όλα')}
                </Typography>) : t('Όλα')}
                labelPlacement="end"
                className={!phone ? classes.radioButton : ''}
              />
              <FormControlLabel
                value="eponimia"
                control={<Radio className={!phone ? classes.radio : ''} />}
                label={!phone ? (<Typography fontSize="14px">
                  {t('Επωνυμία')}
                </Typography>) : t('Επωνυμία')}
                labelPlacement="end"
                className={!phone ? classes.radioButton : ''}
              />
              <FormControlLabel
                value="diakritos"
                control={<Radio className={!phone ? classes.radio : ''} />}
                label={!phone ? (<Typography fontSize="14px">
                  {t('Διακριτικό Τίτλο')}
                </Typography>) : t('Διακριτικό Τίτλο')}
                labelPlacement="end"
                className={!phone ? classes.radioButton : ''}
              />
            </FormGroup>
          </RadioGroup>
        </FormControl>
      </Box>
    </Root>
  );
}
